.actual-temp-label-level-screen,
.actual-temp-level-screen,
.target-temp-container,
.level-area-name {
  color: #fff;
}

.actual-temp-label-level-screen,
.actual-temp-level-screen {
  display: inline-block;
  font-size: 15px;
}

.target-temp-container,
.level-area-name {
  font-size: 26px;
  margin-top: 15px;
}

#level-control {
  touch-action: none; /* Disabilita lo scroll su drag */

  width: 60%;
  max-width: 300px;
  height: 70%;
  background-color: red;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.level-control-container {
  background-color: green;
  width: 80%;
  max-width: 900px;
  height: 70%;
  position: absolute;
  text-align: center;
  border-radius: 15px;
}

#level-fill-area {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: blue;
}

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
