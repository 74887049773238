p{
  margin: 0;
}
.sensor {
    background-color: #e3f2fd; /* Colore azzurro pastello flat */
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06); /* Ombra per staccare la card dallo sfondo */
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: auto;
    font-family: 'Arial', sans-serif;
    color: #333; /* Colore testo */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .svgIcon{
    fill: #333;
  }
  
  .sensor:hover {
    transform: translateY(-5px); /* Solleva la card al passaggio del mouse */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.12); /* Ombra più intensa */
  }
  
  .sensor h3 {
    font-size: 1.5em; /* Dimensione del titolo */
    margin-top: 5px;
    margin-bottom: 10px; /* Spazio sotto il titolo */
    color: #007bff; /* Colore blu per il titolo */
  }
  
  .sensor span,
  .sensor div {
    font-size: 1em; /* Dimensione normale per i contenuti */
    margin-bottom: 8px; /* Spaziatura tra gli elementi */
  }
  
  .sensor div:last-child {
    margin-bottom: 0; /* Rimuove l'ultimo margine inferiore */
  }
  
  .sensor .status {
    font-weight: bold; /* Stato in grassetto */
    color: #28a745; /* Verde per lo stato positivo */
  }
  
  .sensor .status.off {
    color: #dc3545; /* Rosso per lo stato negativo */
  }
  
  /* Media Query per dispositivi mobili */
  @media (max-width: 600px) {
    .sensor {
      padding: 15px;
      max-width: 100%; /* Occupa tutto lo spazio disponibile */
    }
  
    .sensor h3 {
      font-size: 1.3em;
    }
  
    .sensor span,
    .sensor div {
      font-size: 0.9em;
    }
  }
  
  .sensor-row {
    display: flex; /* Disposizione orizzontale */
    justify-content: space-between; /* Spaziatura tra l'etichetta e lastUpdate */
    align-items: center; /* Allineamento verticale centrato */
    gap: 10px; /* Spazio tra i due elementi */
  }
  
  .sensor-row p {
    margin: 0; /* Rimuove margini dai paragrafi */
    font-size: 1em;
    color: #333;
  }
  
  .last-update {
    text-align: right; /* Allinea il testo a destra */
    flex: 0 0 auto; /* Evita che si espanda per occupare tutto lo spazio */
    font-size: 0.9em; /* Riduci leggermente la dimensione del testo */
    color: #666; /* Colore più chiaro per il lastUpdate */
  }
  
  .inline{
    display: flex;
    margin: 0;
  }