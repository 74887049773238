.sensors-container {
  text-align: center;
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(300px, 1fr)
  ); /* Colonne flessibili */
  gap: 20px; /* Spaziatura tra le card */
  row-gap: 30px; /* Spaziatura verticale tra le righe */
  padding: 20px;
  max-width: 1200px; /* Larghezza massima del contenitore */
  margin: 0 auto;
}
