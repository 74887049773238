.area-container {
  position: relative;
  color: #fff;
  background-color: #62ddbd; /* #f39c12;*/
  height: 125px;
  width: calc(50% - 30px);
  margin: 5px;
  border-radius: 7px;
  padding: 10px;
}
#areas-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.area-status {
  font-size: 35px;
  text-align: right;
}
.area-name {
  text-transform: uppercase;
}
.area-heat {
  float: right;
}
.area-lastupdated,
.area-humidity,
.area-lastupdated {
  float: right;
}
.battery-icon {
  color: red;
  position: absolute;
  bottom: 0px;
  left: 3px;
}

.overlay {
  position: absolute;
  top: 10;
  left: 0;
  width: 100%;
  height: 80%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.overlay-content {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.area-info {
  display: flex; /* Attiva Flexbox */
  flex-direction: column; /* Metti gli elementi in colonna */
  gap: 4px; /* Spaziatura tra gli elementi */
  align-items: flex-end; /* Allinea gli elementi a destra */
  margin-top: 5px;
}
