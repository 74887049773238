input[type="radio"] {
  display: none;
}

label {
  color: #fff;
  z-index: 10;
  cursor: pointer;
}

h4 {
  margin: 0;
  font-weight: 500;
}

.active-font {
  color: black !important;
}

.main-container {
  display: inline-block;
  vertical-align: middle;
  width: 250px;
  height: 50px;
  border-radius: 100px;
  position: relative;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.5);
}
.left-container-backgroundcolor {
  background-color: #c4c4c4;
}
.center-container-backgroundcolor {
  background-color: #ffa060;
}
.right-container-backgroundcolor {
  background-color: rgb(255, 104, 96);
}

.switch {
  height: 100%;
  width: 80px;
  border-radius: 100px;
  position: absolute;
  transition: transform 0.1s ease-out;
}

/*---------------- LEFT ------------------- */
.switch.left-to-center {
  animation-name: leftToCenter;
  animation-duration: 0.5s;
}

.switch.left-to-right {
  animation-name: leftToRight;
  animation-duration: 0.5s;
}

.switch.left-position {
  left: 3px;
  transform: translateX(0); /* Posizione iniziale */
}

.left-label {
  position: absolute;
}
.left-backgroundcolor {
  background-color: #9e9e9e;
}

#left + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 80px;
  border-radius: 100px;
}

#left:checked + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 80px;
  border-radius: 100px;
}

@keyframes leftToCenter {
  from {
    left: 3px;
  }
  to {
    left: 85px;
  }
}

@keyframes leftToRight {
  from {
    left: 3px;
  }
  to {
    left: 167px;
  }
}

/* -------------------- center ------------------ */
.switch.center-to-left {
  animation-name: centerToLeft;
  animation-duration: 0.5s;
}

.switch.center-to-right {
  animation-name: centerToRight;
  animation-duration: 0.5s;
}

.switch.center-position {
  left: 85px;
}

.center-label {
  position: absolute;
  left: 85px;
}
.center-backgroundcolor {
  background-color: #ff6c00;
}
#center + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 80px;
  border-radius: 100px;
}

#center:checked + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 80px;
  border-radius: 100px;
}

@keyframes centerToLeft {
  from {
    left: 85px;
  }
  to {
    left: 3px;
  }
}

@keyframes centerToRight {
  from {
    left: 85px;
  }
  to {
    right: 1px;
  }
}

/* ------------------- RIGHT ------------------- */
.switch.right-to-left {
  animation-name: rightToLeft;
  animation-duration: 0.5s;
}

.switch.right-to-center {
  animation-name: rightToCenter;
  animation-duration: 0.5s;
}

.switch.right-position {
  left: 167px;
}

.right-label {
  position: absolute;
  right: 2px;
}
.right-backgroundcolor {
  background-color: #ff0800;
}

#right + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 80px;
  border-radius: 100px;
}

#right:checked + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 80px;
  border-radius: 100px;
}

@keyframes rightToLeft {
  from {
    left: 167px;
  }
  to {
    left: 3px;
  }
}

@keyframes rightToCenter {
  from {
    left: 167px;
  }
  to {
    right: 85px;
  }
}
