.spinner-container {
  display: inline-block;
}

.loading-spinner {
  border: 4px solid #f3f3f3; /* Colore di sfondo */
  border-top: 4px solid #3498db; /* Colore della parte superiore */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
