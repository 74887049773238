.title {
  font-size: 35px;
  line-height: 57px;
  margin-left: 10px;
}
#update-info {
  float: right;
  text-align: right;
  margin-right: 5px;
}
.spinner-container {
  margin: 3px;
  margin-left: 8px;
}
